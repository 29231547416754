.login-paper {
    width: 300px;
    min-width: 150px;
    height: 300px;
    margin: 50px auto;
    padding: 50px;
    text-align: center;
}

.login-subtitle {
    padding-bottom: 20px;
}

.signin-button {
    cursor: pointer;
    display: block;
    width: 100%;
}

.btn-disclaimer {
    color: rgb(207, 3, 20) !important;
}