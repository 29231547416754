.category-paper {
    text-align: center;
    color: darksalmon;
}

.bg {
    /*background-color: aqua;*/
}

.category-paper ul {
    padding: 0;
}

.loading-paper {
    width: 240px;
    height: 70px;
    margin: auto !important;
    text-align: center;
    padding: 30px;
}