.paper {
    margin: 30px auto !important;
    padding: 20px;
    border-radius: 0 !important;
    text-align: center;
    /* max-width: 400px; */
}

.choice {
    padding: 10px;
    border-radius: 0 !important;
    cursor: pointer;
    border: 1px solid #000;
}

.question {
    padding-bottom: 20px;
}

.question-title {
    text-align: center;
}

.correct {
    background-color: green !important;
    color: #ffffff;
}

.wrong {
    background-color: rgb(255, 0, 0) !important;
    color: #ffffff;
}

#btnNextQuestion {
    visibility: hidden;
    display: none;
    margin-left: 10px;
    margin-top: 5px;
}

.loading-paper {
    width: 100%;
    height: 70px;
    margin: auto;
    text-align: center;
    padding: 30px;
}

#answer-message {
    visibility: hidden;
    padding: 10px;
    display: none;
    background-color: orange;
}

#explanation {
    visibility: hidden;
    display: none;
    background-color: burlywood;
    font-style: italic;
    border-radius: 0;
    padding: 10px;
}

.explanation {
    text-align: left;
}

.display-linebreak {
    white-space: nowrap;
}

.no-question-available {
    width: 70%;
    margin: 200px auto;
    padding: 20px;
    color: rgb(200, 0, 0)
}

.correct-answer-highlight {
    text-decoration: underline !important;
    font-weight: bolder;
}