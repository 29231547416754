body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-paper {
    width: 300px;
    min-width: 150px;
    height: 300px;
    margin: 50px auto;
    padding: 50px;
    text-align: center;
}

.login-subtitle {
    padding-bottom: 20px;
}

.signin-button {
    cursor: pointer;
    display: block;
    width: 100%;
}

.btn-disclaimer {
    color: rgb(207, 3, 20) !important;
}
.paper {
    margin: 30px auto !important;
    padding: 20px;
    border-radius: 0 !important;
    text-align: center;
    /* max-width: 400px; */
}

.choice {
    padding: 10px;
    border-radius: 0 !important;
    cursor: pointer;
    border: 1px solid #000;
}

.question {
    padding-bottom: 20px;
}

.question-title {
    text-align: center;
}

.correct {
    background-color: green !important;
    color: #ffffff;
}

.wrong {
    background-color: rgb(255, 0, 0) !important;
    color: #ffffff;
}

#btnNextQuestion {
    visibility: hidden;
    display: none;
    margin-left: 10px;
    margin-top: 5px;
}

.loading-paper {
    width: 100%;
    height: 70px;
    margin: auto;
    text-align: center;
    padding: 30px;
}

#answer-message {
    visibility: hidden;
    padding: 10px;
    display: none;
    background-color: orange;
}

#explanation {
    visibility: hidden;
    display: none;
    background-color: burlywood;
    font-style: italic;
    border-radius: 0;
    padding: 10px;
}

.explanation {
    text-align: left;
}

.display-linebreak {
    white-space: nowrap;
}

.no-question-available {
    width: 70%;
    margin: 200px auto;
    padding: 20px;
    color: rgb(200, 0, 0)
}

.correct-answer-highlight {
    text-decoration: underline !important;
    font-weight: bolder;
}
.category-paper {
    text-align: center;
    color: darksalmon;
}

.bg {
    /*background-color: aqua;*/
}

.category-paper ul {
    padding: 0;
}

.loading-paper {
    width: 240px;
    height: 70px;
    margin: auto !important;
    text-align: center;
    padding: 30px;
}
.app-title {
    flex: 1 1;
}

.appbar {
    height: 60px;
}
* {
    padding: 0;
}

.container {
    margin-top: 60px;
}

.side-intro-title {
    text-align: center;
    margin-top: 10px !important;
}

.side-intro-par {
    text-align: justify;
    padding: 10px;
}
.welcome-title {
    margin-top: 60px !important;
    margin-bottom: 30px !important;
    text-align: center;
}
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: red;
}
